import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from './Navbar';
import Section from './Section';
import SectionHeading from './SectionHeading';
import useWindowSize from './useWindowSize';
import AnimateOnScroll from './AnimateOnScroll';
import CopyrightFooter from './CopyrightFooter';

import image1 from './images/dashboard2.png';
import image2 from './images/dashboard3.png';
import image3 from './images/dashboard2.png';
import ClimAlertDashboardImage from './images/background1.png';
import GreenIrrigationDashboardImage from './images/GreenIrrigationDashboard.PNG';
import agImage from './images/agImage.PNG';

import './Solutions.css';


function Solutions() {
    const size = useWindowSize();
    const shouldAnimate = size.width > 900;
    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
    return (
        <div className="Solutions">
            <div className="solutions-header">
                <h1>Our Solutions</h1>
                <p>Discover how our innovative platforms can help you mitigate climate risks across various sectors. We offer a wide portofolio of products that address
                    multiple sectors and their needs.
                </p>
                <div className="scroll-buttons">
                    <button onClick={() => scrollToSection('energy')}>Energy</button>
                    <button onClick={() => scrollToSection('agriculture')}>Agriculture</button>
                    <button onClick={() => scrollToSection('disaster-response')}>Prepare</button>
                    { /* <button onClick={() => scrollToSection('healthcare')}>Healthcare</button> */ }
                </div>
            </div>

            <div id="energy">
            
            {shouldAnimate ? (
                <AnimateOnScroll reappear threshold={0.1} direction="right">
                    <Section
                    heading="ClimEnergy"
                    description={
                        <>
                            Our ClimEnergy product line currently offers two products: 
                            <strong> ClimAlert - Early Alert System</strong> and 
                            <strong> ClimSolar</strong>. With ClimAlert, you can be prepared for heatwaves and high fire danger events well in advance. ClimSolar offers accurate solar forecasting so you can optimize solar usage.
                        </>
                    }
                    imgSrc={ClimAlertDashboardImage}
                    imgAlt="Description of image 1"
                    reverse={false}
                    layers={[
                        { className: 'dark-blue', style: { width: '99%', height: '127%', transform: 'rotate(5.51deg)', backgroundColor: '#088395' } },
                        { className: 'screen-layer-right', style: { width: '81.5%', height: '102%', backgroundColor: 'black' } }
                    ]}
                    />
                </AnimateOnScroll>
                ) : (
                    <Section
                        heading="Imagine a future where we can foresee climate threats"
                        description="From wildfire prevention to optimizing agriculture and healthcare, we manage resources and protect our environment by mitigating climate risks."
                        imgSrc={image1}
                        imgAlt="Description of image 1"
                        reverse={false}
                        layers={[]} // Empty layers to simplify for smaller screens
                    />
                )}

                </div>

                <div id="agriculture">
                {shouldAnimate ? (
                <AnimateOnScroll reappear threshold={0.1} direction="left">
                    <Section
                    heading="ClimAg"
                    description={
                        <>
                            The ClimAg product line consists of multiple products that assist agricultural businesses. We offer 
                            <strong> ClimWater</strong>, <strong>ClimPrecip</strong>, and <strong>GreenIrrigation</strong>, which give farmers accurate information about water, precipitation, and solar power to best manage their crops.
                        </>
                    }
                    imgSrc={image3}
                    imgAlt="The ClimAg product line consists of multiple products that assist agricultural businesses. We offer ClimWater, ClimPrecip, and GreenIrrigation which give farmers accurate information
                    about water, precipitation, and solar power to best manage their crops."
                    reverse={true}
                    layers={[
                        { className: 'light-blue', style: { width: '98%', height: '130%', transform: 'rotate(-5.51deg)', backgroundColor: '#37B7C3' } },
                        { className: 'screen-layer-left', style: { width: '81.6%', height: '102.5%', backgroundColor: 'black' } }
                    ]}
                    />
                </AnimateOnScroll>
                ) : (
                <Section
                    heading="Safeguarding our cities from devastating fires"
                    description="This is a description for section 2."
                    imgSrc={image2}
                    imgAlt="Description of image 2"
                    reverse={true}
                    layers={[]} // Empty layers to simplify for smaller screens
                />
                )}

                </div>

                
                
                <div id="disaster-response">  
        
                {shouldAnimate ? (
                <AnimateOnScroll reappear threshold={0.1} direction="right">
                    <Section
                    heading="ClimPrepare"
                    description="Our ClimPrepare portfolio provides seasonal fire predictions and ensures timely disaster response, safeguarding our land from devastating fires."
                    imgSrc={image2}
                    imgAlt="Description of image 3"
                    reverse={false}
                    layers={[
                        { className: 'dark-blue', style: { width: '99%', height: '127%', transform: 'rotate(5.51deg)', backgroundColor: '#088395' } },
                        { className: 'screen-layer-right', style: { width: '81.5%', height: '102%', backgroundColor: 'black' } }
                    ]}
                    />
                </AnimateOnScroll>
                ) : (
                <Section
                    heading="Ensuring our healthcare systems are ready for heatwaves"
                    description="This is a description for section 3."
                    imgSrc={image3}
                    imgAlt="Description of image 3"
                    reverse={false}
                    layers={[]}
                />
            )}


           

           {}
            </div>
            <CopyrightFooter />
      </div>
    );
}
  
export default Solutions;