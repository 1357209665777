import React, { useEffect, useRef } from "react";
import Globe from "globe.gl";

import * as THREE from 'three';

const GlobeComponent = () => {
  const globeRef = useRef();
  const globeInstance = useRef(null);

  useEffect(() => {
    if (!globeRef.current) return;
    globeInstance.current = Globe()(globeRef.current);

    globeInstance.current
      .globeImageUrl("//unpkg.com/three-globe/example/img/earth-blue-marble.jpg")
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .backgroundColor("rgba(0,0,0,0)")
      .pointOfView({ lat: 0, lng: 0, altitude: 1.4 })
      .showAtmosphere(false);

    globeInstance.current.controls().enableZoom = false;
    globeInstance.current.controls().autoRotate = true;
    globeInstance.current.controls().autoRotateSpeed = 0.7;

    const CLOUDS_IMG_URL = '//unpkg.com/three-globe/example/clouds/clouds.png';

    new THREE.TextureLoader().load(CLOUDS_IMG_URL, (cloudsTexture) => {
        const cloudsMaterial = new THREE.MeshPhongMaterial({
          map: cloudsTexture,
          transparent: true,
          opacity: 0.8,
        });
  
        const globeRadius = globeInstance.current.getGlobeRadius();
        const cloudsGeometry = new THREE.SphereGeometry(globeRadius * 1.01, 75, 75);
        const cloudsMesh = new THREE.Mesh(cloudsGeometry, cloudsMaterial);
  
        globeInstance.current.scene().add(cloudsMesh);
      });
  
      // Handle window resize
      const handleResize = () => {
        if (globeInstance.current && globeRef.current) {
          const containerWidth = globeRef.current.offsetWidth;
          const containerHeight = globeRef.current.offsetHeight;

          globeInstance.current
            .width(containerWidth)
            .height(containerHeight);
          
          globeInstance.current.pointOfView({ lat: 0, lng: 0, altitude: 1.4 });
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial size adjustment

    return () => {
        window.removeEventListener('resize', handleResize);
        if (globeRef.current) globeRef.current.innerHTML = "";
    };
  }, []);

  return <div ref={globeRef} className="globe" />;
};

export default GlobeComponent;