import React, { useState } from 'react';
import './Resources.css';
import publication1 from './images/ocean.jpg';
import publication2 from './images/drought.jpg';
import publication3 from "./images/arctic.jpg";
import award1 from "./images/calseedaward.png";
import award2 from "./images/verge23.jpeg";
import award3 from "./images/american-made.png";
import DigUtilImage from "./images/dur2p1-winners-data-track.jpg";
import ArcticAward from "./images/arctic-award.jfif";
import etwaImage from "./images/etwaImage.png";
import vergeImage from "./images/vergeImage.png";
import epicImage from "./images/epicSympImage.png";
import CopyrightFooter from './CopyrightFooter';



const Resources = () => {
  const [newsPage, setNewsPage] = useState(1);
  const [awardsPage, setAwardsPage] = useState(1);
  const [publicationsPage, setPublicationsPage] = useState(1);

  const itemsPerPage = 3;

  const newsData = [
    {
      img: etwaImage,
      alt: "ETWA Conference",
      title: "Climformatics at Energy Trading Week Americas 2024",
      date: "October 26, 2024",
      description:
        "We’re pleased to announce that Climformatics will be attending Energy Trading Week Americas, hosted by Commodities People, from October 29, 2024, at 7:00 AM, through October 30, 2024, at 12:00 PM at The Woodlands Waterway Marriott Hotel & Convention Center, 1601 Lake Robbins Dr, The Woodlands, TX. As a women-owned leader in predictive climate solutions, Climformatics is excited to engage with industry experts on how advanced climate data can support robust and resilient strategies in energy trading.",
      link: "https://americas.energytradingweek.com/",
    },

    {
      img: vergeImage,
      alt: "Verge 2024",
      title: "Climformatics is Thrilled to Attend VERGE 2024 & Cleantech Open Global Forum!",
      date: "October 26, 2024",
      description:
        "Our team is excited to be part of VERGE 2024 and the Cleantech Open Global Forum 2024, two premier events where leaders come together to accelerate climate solutions. VERGE, known for its focus on clean technology, provides a platform to discuss how our advanced climate forecasting can aid in reducing climate risks globally. Similarly, at the Cleantech Open Global Forum, we’ll connect with innovators driving the future of sustainable technology.",
      link: "https://www.cleantechopen.org/en/page/cleantech-open-global-forum-en",
    },

    {
      img: epicImage,
      alt: "EPIC Symposium",
      title: "EPIC Symposium 2024",
      date: "October 25, 2024",
      description:
        "Our team is excited to be part of VERGE 2024 and the Cleantech Open Global Forum 2024, two premier events where leaders come together to accelerate climate solutions. VERGE, known for its focus on clean technology, provides a platform to discuss how our advanced climate forecasting can aid in reducing climate risks globally. Similarly, at the Cleantech Open Global Forum, we’ll connect with innovators driving the future of sustainable technology.",
      link: "https://www.energy.ca.gov/event/outreach/2024-10/2024-electric-program-investment-charge-epic-symposium",
    },

    {
      img: vergeImage,
      alt: "VERGE 23",
      title: "VERGE 23",
      date: "October 24, 2023",
      description:
        "Our team is excited to be part of VERGE 2024 and the Cleantech Open Global Forum 2024, two premier events where leaders come together to accelerate climate solutions. VERGE, known for its focus on clean technology, provides a platform to discuss how our advanced climate forecasting can aid in reducing climate risks globally. Similarly, at the Cleantech Open Global Forum, we’ll connect with innovators driving the future of sustainable technology.",
      link: "https://www.linkedin.com/search/results/content/?keywords=climformatics%20verge%2023",
    },
    // Add the rest of your news items here
  ];

  const awardsData = [
    {
      img: DigUtilImage,
      alt: "Digitizing Utilities Award 2024",
      title: "Digitizing Utilities Award 2024",
      date: "July 17, 2024",
      description:
        "This team aims to build an advanced software application that serves as a decision support tool for utilities to evaluate and mitigate the climate risks threatening the utility assets and user communities. This tool will provide actionable analytics for extreme weather events that occur up to a year in advance.",
      link: "https://www.nrel.gov/news/program/2024/digitizing-utilities-prize-round-2-phase-1-winners-announced.html",
    },
    {
      img: award1,
      alt: "CALSEED Prototype Award",
      title: "CALSEED Prototype Award",
      date: "February 14, 2024",
      description:
        "Climformatics builds models to simulate the global climate using state-of-the-art, Nobel-Prize winning algorithms. It predicts with a high degree of certainty the climate and weather state for long-term timescales at specific geographic locations.",
      link: "https://www.newenergynexus.com/blog/6-startups-that-could-shape-californias-clean-energy-transition/",
    },
    {
      img: award3,
      alt: "American-Made Solar Prize",
      title: "American-Made Solar Prize",
      date: "2022",
      description:
        "Climformatics was a finalist in the American-Made Solar Prize, a multimillion-dollar competition aimed at energizing U.S. solar innovation by accelerating the entrepreneurial process from years to months through a series of contests.",
      link: "https://network.americanmadechallenges.org/prize-winners/ckAJiebwH6hGeKsWc4Losh",
    },
    {
      img: ArcticAward,
      alt: "DS Discovery Excellence Award",
      title: "DS Discovery Excellence Award 2024",
      date: "May 20, 2024",
      description:
        "We extend our sincerest congratulations to Chunting Z, Weijie Yang Derek Yao, and Nick Pan from the Arctic Ice Forecasting Dynamics team - Climformatics Inc. for their remarkable achievement in presenting their insightful poster titled 'Arctic Ice Forecasting Dynamics.'",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7192764255383744512/",
    },
  ];

  const publicationsData = [
    {
      img: publication1,
      alt: "Publication 1",
      title:
        "The Oceanic Barrier Layer in the Eastern Indian Ocean as a Predictor for Rainfall Over Indonesia and Australia",
      date: "November 6, 2021",
      description:
        "Salinity stratification near Sumatra creates a thermal barrier that warms the upper ocean from November to January, enhancing local rainfall and impacting monsoon moisture distribution across the tropical Indo-Pacific, thereby influencing seasonal rainfall predictions in the Indonesian-Australian region.",
      link: "https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2021GL094519",
    },
    {
      img: publication2,
      alt: "Reversing Sahelian Droughts",
      title: "Reversing Sahelian Droughts",
      date: "July 3, 2021",
      description:
        "Research on cooling the Indian Ocean via pipes to reverse Sahel droughts shows potential to increase regional precipitation but risks reducing rainfall in sub-Saharan East Africa, suggesting millions of pipes would be needed for such a large-scale intervention.",
      link: "https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2021GL093129",
    },
    {
      img: publication3,
      alt: "Increasing Arctic Sea Ice Albedo",
      title: "Increasing Arctic Sea Ice Albedo Using Localized Reversible Geoengineering",
      date: "May 21, 2018",
      description:
        "The method involves applying reflective materials like glass microspheres to young Arctic sea ice to enhance its reflectivity and preservation, potentially cooling the Arctic and mitigating global warming by restoring ice area and volume.",
      link: "https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2018EF000820",
    },
  ];

  const paginate = (data, page) =>
    data.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const renderSection = (data, currentPage, setPage, sectionTitle) => {
    const totalPages = Math.ceil(data.length / itemsPerPage);

    return (
      <>
        <div className="resources-header-section">{sectionTitle}</div>
        <div className="resources-container">
          {paginate(data, currentPage).map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="resource-box"
              key={index}
            >
              <img src={item.img} alt={item.alt} />
              <div className="resource-info">
                <h1>{item.title}</h1>
                <p className="date-posted">{item.date}</p>
                <p>{item.description}</p>
              </div>
            </a>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <button
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="spacer"></div>
      {renderSection(newsData, newsPage, setNewsPage, "News")}
      {renderSection(awardsData, awardsPage, setAwardsPage, "Awards & Advancements")}
      {renderSection(publicationsData, publicationsPage, setPublicationsPage, "Publications")}
      <CopyrightFooter />
    </div>
  );
};

export default Resources;
