import React from 'react';
import logo from './images/newlogo.png';

const CopyrightFooter = () => {
  return (
    <footer style={{
      textAlign: 'center',
      padding: '40px 20px',
      marginTop: '20px',
      color: '#333',
      fontFamily: 'Arial, sans-serif'
    }}>
      {/* Company Logo */}
      <div style={{ marginBottom: '20px' }}>
        <img src={logo} alt="Company Logo" style={{ height: '60px' }} />
      </div>

      {/* Hyperlinks / Sections */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', marginBottom: '20px', fontSize: '1.5rem' }}>
        <a href="/about" style={{ textDecoration: 'none', color: '#333' }}>About</a>
        <a href="/Solutions" style={{ textDecoration: 'none', color: '#333' }}>Solutions</a>
        <a href="https://climformatics-demo-dashboard.web.app/" style={{ textDecoration: 'none', color: '#333' }}>Demo</a>
        <a href="/media" style={{ textDecoration: 'none', color: '#333' }}>Media</a>
        <a href="/terms-of-use" style={{ textDecoration: 'none', color: '#333' }}>Terms & Conditions</a>
        <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#333' }}>Privacy Policy</a>
      </div>

      {/* Company Address and Social Links */}
      <div style={{ marginBottom: '20px' }}>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>5381 Ridgewood Drive</p>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>Fremont, California, 94555</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
          <a href="https://www.linkedin.com/company/climformatics-inc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin" style={{ fontSize: '24px', color: '#333' }}></i>
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook" style={{ fontSize: '24px', color: '#333' }}></i>
          </a>
        </div>
      </div>

      {/* Copyright */}
      <div>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>&copy; 2024 Climformatics, Inc.</p>
      </div>
    </footer>
  );
}

export default CopyrightFooter;