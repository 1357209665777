import React from 'react';
import './About.css'; 
import missionImage from './images/collage1.png';
import storyImage from './images/collage2.png';
import cofounder2Image from './images/detelina.gif'; 
import cofounder1Image from './images/subarna.jpg';
import teamMember1Image from './images/divyam.jpg';
import teamMember2Image from './images/ryan.jpg';
import teamMember3Image from './images/teamMember3Image.jpeg';
import teamMember4Image from './images/Nirvik.jpeg';
import lukaImage from './images/luka.jfif';
import image1 from './images/calseed.png'; 
import image2 from './images/american.png';
import intern1Image from './images/ella.jfif';
import intern2Image from './images/Weijie Yang.jpeg';
import intern3Image from './images/Derek Yao.jpeg';
import intern4Image from './images/Nick Zhao.jpeg';
import intern7 from './images/jacobmooradian.jfif';
import CopyrightFooter from './CopyrightFooter';


const About = () => {
    return (
      <div className="about-page">
        <div className="spacer"></div>
        <div className="mission-story-container">
            <div className="our-story-container">
                <div className="our-story-statement">
                    <h1>The Climformatics Story</h1>
                    <p>At Climformatics, we specialize in advanced climate forecasting solutions that empower industries
                        to anticipate and mitigate the impacts of extreme weather and climate change.
                        Using cutting-edge data science, climate modeling, and AI, we provide highly accurate,
                        localized climate predictions for critical timeframes—from 1 month to 1 year ahead.
                            Our unique approach integrates comprehensive Earth systems data, capturing the
                            intricate interactions of atmosphere, oceans, emissions, and ecosystems,
                            to deliver precise, actionable insights.
                    </p>
                    <p>
                    Our solutions serve utilities, emergency management agencies, agriculture, water resource management,
                    and more, enabling them to make proactive, climate-smart decisions. From fire-weather predictions to
                    solar, wind, and hydropower forecasts, our tools are designed to optimize resilience, resource
                    efficiency, and long-term sustainability.
                    </p>
                    <p>
                    With a globally scalable Data-as-a-Service (DaaS) model, Climformatics is transforming climate forecasting,
                    bridging the gap between short-term weather predictions and long-term climate projections. Through strategic
                    partnerships and industry collaborations, we are committed to making climate resilience accessible and
                    effective for businesses and communities worldwide.
                    </p>
                </div>
                <div className="our-story-image-container">
                    <img src={storyImage} alt="Story Image" className="story-image"/>
                </div>
            </div>
            <div className="mission-container">
                <div className="mission-image-container">
                    <img src={missionImage} alt="Mission Image" className="mission-image"/>
                </div>
                <div className="mission-statement">
                    <h1>Our Mission</h1>
                    <p>Our mission is to empower communities, businesses, and governments with inclusive climate forecasting
                        solutions that enhance disaster preparedness, advance equitable access to clean energy,
                        and drive sustainable adaptation. With a commitment to diversity, equity, and collaboration,
                        we work to build resilient systems that protect people, preserve ecosystems, and foster
                            a harmonious future with the planet.
                    </p>
                </div>
            </div>
        </div>
        <div className="team-container">    
            <div className="team-title">
                <h1>Meet The Team</h1>
                <h4></h4>
            </div>
            
            <div className="team-grid">
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={cofounder1Image} alt="Dr. Subarna Bhattacharyya" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Dr. Subarna Bhattacharyya</div>
                        <div className="team-card-position">CEO & Co-Founder</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/subarna-bhattacharyya/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:subarna.bhattacharyya@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="team-card">
                    <div className="team-card-image">
                        <img src={cofounder2Image} alt="Dr. Detelina Ivanova" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Dr. Detelina Ivanova</div>
                        <div className="team-card-position">CTO & Co-Founder</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/detelinaivanova/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:detelina.ivanova@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="team-card">
                    <div className="team-card-image">
                        <img src={teamMember1Image} alt="Divyam Goel" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Divyam Goel</div>
                        <div className="team-card-position">Lead Machine Learning Engineer</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/divyam-g-33775a114/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:divyam.goel@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={teamMember4Image} alt="Nirvik Sen" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Nirvik Sen</div>
                        <div className="team-card-position">Head of Marketing & Research</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/nirviksen/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:nirvik.sen@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={teamMember3Image} alt="Anant Mishra" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Anant Mishra</div>
                        <div className="team-card-position">Head of Product & Strategy</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/anant--mishra/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:anant.mishra@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={teamMember2Image} alt="Ryan L." className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Ryan Lim</div>
                        <div className="team-card-position">Data Analyst</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/rlim8/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:ryan.lim@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={lukaImage} alt="Luka Cheney" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Luka Cheney</div>
                        <div className="team-card-position">Data Scientist</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/luka-cheney" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:luka.cheney@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="team-card">
                    <div className="team-card-image">
                        <img src={intern7} alt="Jacob Mooradian" className="team-image" />
                    </div>
                    <div className="team-card-info">
                        <div className="team-card-name">Jacob Mooradian</div>
                        <div className="team-card-position">Software Engineer</div>
                        <div className="team-card-icons">
                            <a href="https://www.linkedin.com/in/jacob-mooradian-2406511b3/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:jacob.mooradian@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="advisors-container">
            <div className="advisors-title">
                <h1>Our Advisors</h1>
            </div>
            <div className="advisors-grid">
                <div className="advisor-card">
                    <h3>Dr. Annemarie Meike</h3>
                    <p>Business Advisor / ex-LLNL IP Strategist & Business Dev Executive</p>
                    <div className="advisor-icons">
                        <a href="https://www.linkedin.com/in/annemarie-meike-phd-jd-899432/" target="_blank" rel="noopener noreferrer" className="icon-link">
                        <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>

                <div className="advisor-card">
                    <h3>Marc Gottschalk</h3>
                    <p>Attorney and Business Advisor</p>
                    <div className="advisor-icons">
                        <a href="https://www.linkedin.com/in/marcgottschalk/" target="_blank" rel="noopener noreferrer" className="icon-link">
                        <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
                <div className="advisor-card">
                    <h3>Dr. Peter Williams</h3>
                    <p>Retired IBM Distinguished Engineer, Chair of United Nations Disaster Risk Resilience US Chapter ARISE-US</p>
                    <div className="advisor-icons">
                        <a href="https://www.linkedin.com/in/peter-williams-de-phd/" target="_blank" rel="noopener noreferrer" className="icon-link">
                        <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
                <div className="advisor-card">
                    <h3>Sourav Mazumder</h3>
                    <p>IBM Distinguished Data Scientist</p>
                    <div className="advisor-icons">
                        <a href="https://www.linkedin.com/in/souravmazumder/" target="_blank" rel="noopener noreferrer" className="icon-link">
                        <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="interns-container">
            <div className="interns-title">
                <h1>Interns</h1>
            </div>
            <div className="interns-grid">
                <div className="intern-card">
                    <img src={intern1Image} alt="Intern 1" className="intern-image" />
                    <div className="intern-info">
                        <div className="intern-name">Ella Young</div>
                        <div className="intern-position">Technology & Policy Research Intern</div>
                        <div className="intern-icons">
                            <a href="https://www.linkedin.com/in/ella-young-13bbbb273/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:ella.young@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="intern-card">
                    <img src={intern2Image} alt="Intern 2" className="intern-image" />
                    <div className="intern-info">
                        <div className="intern-name">Weijie Yang</div>
                        <div className="intern-position">Data Science Intern</div>
                        <div className="intern-icons">
                            <a href="https://www.linkedin.com/in/john-smith" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:john.smith@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                             </a>
                        </div>
                    </div>
                </div>

                <div className="intern-card">
                    <img src={intern3Image} alt="Intern 3" className="intern-image" />
                    <div className="intern-info">
                        <div className="intern-name">Derek Yao</div>
                        <div className="intern-position">Data Science Intern</div>
                        <div className="intern-icons">
                            <a href="https://www.linkedin.com/in/derekyao/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:john.smith@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                             </a>
                        </div>
                    </div>
                </div>

                <div className="intern-card">
                    <img src={intern4Image} alt="Intern 4" className="intern-image" />
                    <div className="intern-info">
                        <div className="intern-name">Nick Zhao</div>
                        <div className="intern-position">Data Science Intern</div>
                        <div className="intern-icons">
                            <a href="https://www.linkedin.com/in/derekyao/" target="_blank" rel="noopener noreferrer" className="icon-link">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="mailto:john.smith@climformatics.com" className="icon-link">
                                <i className="fas fa-envelope"></i>
                             </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="founders-background">
            <p>
                Prior to the establishment of their enterprise, our two founders, Detelina Ivanova Ph.D. and Subarna Bhattacharya Ph.D. were engaged at the Program for Climate Model Diagnosis and Intercomparison at Lawrence Livermore National Laboratory (LLNL). Collaborating with their peers at the laboratory, they were instrumental in contributing to the assessment reports of the Intergovernmental Panel on Climate Change (IPCC). Recognizing the imperative to integrate global climate modeling and forecasting into the core of business decision-making for healthcare networks, they launched Climformatics.
            </p>
        </div>

        <div className="calseed-section">
            <img src={image1} alt="Calseed" />
            <img src={image2} alt="American Made" />
        </div>

        <CopyrightFooter />

    </div>
    );
  };

export default About;
