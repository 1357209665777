import React, { useState } from 'react';
import './TestimonialBox.css';

const TestimonialBox = ({ testimonial }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const maxLength = 300; // Adjust the max length as needed
    const needsTruncation = testimonial.quote.length > maxLength;
  
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };
  
    return (
      <>
        <div className="testimonial-box">
          <p>
            {testimonial.quote.substring(0, maxLength)}
            {needsTruncation && '...'}
            {needsTruncation && (
              <span className="read-more" onClick={toggleModal}>
                Read More
              </span>
            )}
          </p>
          <div className="person-info">
            <img
              src={testimonial.personImg}
              alt={testimonial.name}
              className="person-image"
            />
            <div className="person-details">
              <p className="name">{testimonial.name}</p>
              <p className="company">{testimonial.company}</p>
            </div>
          </div>
        </div>
  
        {isModalOpen && (
          <div className="modal-overlay" onClick={toggleModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="close-button" onClick={toggleModal}>
                &times;
              </span>
              
              <p>{testimonial.quote}</p>
            </div>
          </div>
        )}
      </>
    );
  };
  
  export default TestimonialBox;