import React from 'react';

function Section({ heading, description, imgSrc, imgAlt, reverse, layers }) {
    return (
        <div className={`section ${reverse ? 'reverse' : ''}`}>
            <div className="text-content">
                <h1>{heading}</h1>
                <p>{description}</p>
            </div>
            <div className="image-content">
                <div className="image-container">
                    {layers.map((layer, index) => (
                        <div key={index} className={`layer ${layer.className}`} style={layer.style}></div>
                    ))}
                    <img src={imgSrc} alt={imgAlt} className="section-image" />
                </div>
            </div>
        </div>
    );    
}

export default Section;