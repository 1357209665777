import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './Navbar';
import GlobeComponent from './Globe';
import TestimonialBox from './TestimonialBox';

import { testimonials } from './testimonialData';

import Solutions from './Solutions';
import About from './About';
import Media from './Media';

import './App.css';
import CopyrightFooter from './CopyrightFooter';

import carouselImage1 from './images/carouselImage1.avif';
import carouselImage2 from './images/carouselImage2.avif';
import carouselImage3 from './images/carouselImage3.avif';

function App() {
  const contactRef = useRef(null);

  const handleScrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <Navbar handleScrollToContact={handleScrollToContact} />
        <Routes> 
          <Route path="/" element={<AppContent contactRef={contactRef} />} exact />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
        </Routes>
      </div>
    </Router>
  );
}

function ContactSection({ contactRef }) {
  return (
    <div className="contact-section" ref={contactRef}>
      <div className="contact-left">
        <h1>Together, we can build a better future</h1>
        <p>Where every community is safer,</p>
        <p>Every resource is used wisely,</p>
        <p>Every decision is data informed</p>
      </div>
      <div className="contact-right">
        <form className="contact-form" action="https://formsubmit.co/info@climformatics.com" method="POST">
          <label className="select-label">
            <select>
              <option disabled selected>Choose Sector</option>
              <option>Agriculture</option>
              <option>Energy</option>
              <option>Healthcare</option>
              <option>Other</option>
            </select>
          </label>
          <label>
            <input type="text" name="name" placeholder="Your Name" />
          </label>
          <label>
            <input type="text" name="title" placeholder="Your Title" />
          </label>
          <label>
            <input type="text" name="organization" placeholder="Your Organization" />
          </label>
          <label>
            <input type="email" name="email" placeholder="Your Email" />
          </label>
          <label>
            <textarea rows="4" name="message" placeholder="Your Message"></textarea>
          </label>
          <label>
            <input type="text" name="_formsubmit_id" style={{ display: 'none' }} />
          </label>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
}

function SplitCarousel({ slides, autoRotateInterval = 5000 }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, autoRotateInterval);

    return () => clearInterval(interval);
  }, [slides.length, autoRotateInterval]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const { quote, firstWord, image } = slides[currentSlide];

  return (
    <div className="split-carousel-container">
      <div className="split-carousel-image-container">
        <img src={image} alt={`Slide ${currentSlide + 1}`} className="split-carousel-image" />
      </div>

      <div className="split-carousel-text">
        <h2>
          <strong>{firstWord}</strong> {quote}
        </h2>
      </div>

      <div className="carousel-indicators-wrapper">
        <div className="carousel-indicators">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`indicator ${index === currentSlide ? 'current' : ''}`}
              onClick={() => goToSlide(index)}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
}

function AppContent({ contactRef }) {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, contactRef]);

  const slides = [
    {
      firstWord: 'Predict',
      quote: 'Localized Near to Long Term Climate at your desired location, time scale and your line of business one year ahead',
      image: carouselImage1
    },
    {
      firstWord: 'Prepare',
      quote: 'Proactively for seasonal to annual operations of your business',
      image: carouselImage2
    },
    {
      firstWord: 'Protect',
      quote: 'your business from unforeseen extreme climate risks',
      image: carouselImage3
    }
  ];


  return (
    <div className="App">
      <div className="hero">
        <h1 className="hero-title">
          Climatize your business for a <span className="gradient-text">greener</span> future
        </h1>
        <div className="hero-content">
          <div className="hero-text">
            <p>
              Climformatics' innovative AI platform uses physics-based climate models and observed 
              data to predict highly localized climate and extreme weather at hourly to seasonal 
              and annual timescales one year ahead with up to 95% accuracy.
            </p>
            {/* <a href="#learn-more" className="cta-button">Learn More</a> */}
          </div>
          <div className="hero-globe">
            <GlobeComponent />
          </div>
        </div>
      </div>
      
      {/* carousel here*/}

      <SplitCarousel slides={slides} />
      

      {/* testimonial section */}
      <div className="testimonial-section">
        <div className="testimonial-header">
          <h1>What Our Partners Say About Us</h1>
        </div>
        <div className="testimonial-container">
          {testimonials.map((testimonial, index) => (
            <TestimonialBox
              key={index}
              testimonial={testimonial}
              index={index}
            />
          ))}
        </div>
      </div>
      {/* contact section */}
      <ContactSection contactRef={contactRef} />
      <CopyrightFooter />
    </div>
  );
}

export default App;